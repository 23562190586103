<style>
  .tangy-main-container {
    left: 0px;
    position: relative !important
  }

  mat-toolbar,
  mat-tab-header {
    display: none !important;
  }
</style>
<div class="tangy-content tangy-full-width">
  <mat-card>
    <mat-card-title>
      <div id="container" #container></div>
      <table class="card-title">
        <tr>
          <td>{{'Group'|translate}}</td>
          <td>{{groupDetails?.label}}</td>
        </tr>
        <tr>
          <td>{{'Group ID'}}</td>
          <td>{{groupDetails?._id}}</td>
        </tr>
        <tr>
          <td>{{'Form Title'|translate}}</td>
          <td>{{myForm.title}}</td>
        </tr>
        <tr>
          <td>{{'Form ID'|translate}}</td>
          <td>{{myForm.id}}</td>
        </tr>
      </table>
    </mat-card-title>
    <br>
    <div>
      <div *ngFor="let section of meta?.items;let index= index">
        <p>Section {{index+1}}</p>
        <p>ID: {{section.id}}</p>
        <table class="tangy-full-width">
          <th>Variable Name</th>
          <th>Prompt</th>
          <th>Type</th>
          <th>Hint Text</th>
          <th>Options</th>
          <th>Required</th>
          <th>Disabled</th>
          <th>Hidden</th>
          <th></th>
          <tr *ngFor="let input of section.inputs">
            <td>{{input.name}}</td>
            <td>{{input.label}}</td>
            <td>
              {{input.tagName==='TANGY-SELECT'?'single':''}}
              {{input.tagName==='TANGY-RADIO-BUTTONS'?'single':''}}
              {{input.tagName==='TANGY-CHECKBOX'?'single':''}}
              {{input.tagName==='TANGY-CHECKBOXES'?'multiple':''}}
              {{input.type}}
            </td>
            <td>{{input.hintText}}</td>
            <td>
              <span *ngIf="input.value.length>0">
                <span *ngFor="let option of input.value; let index=index">
                  {{option.value}} {{'"'+option.label+'"'}}{{index+1===input.value.length?'':' ,'}}
                </span>
              </span>
            </td>
            <td>{{input.required}}</td>
            <td>{{input.disabled}}</td>
            <td>{{input.hidden}}</td>
          </tr>
        </table>
        <br>
        <hr>
        <br><br>
      </div>
    </div>
  </mat-card>
</div>